<template>
  <table class="table table-bordered subsocre-table">
    <thead>
      <tr>
        <th class="green-td">
          Skill
        </th>
        <th class="level-score green-td">
          Subscore
        </th>
        <th v-if="!hideSubComment" class="green-td">
          Comments
        </th>
      </tr>
    </thead>
    <tbody>
      <tr :key="subScore.id" v-for="subScore in subScores">
        <td class="sub-skill-name">{{ subScore.title[lang] }}</td>
        <td>
          <GradeLevelSelector
            levelType="letter"
            section="speaking"
            :value="subScore.score"
            @change="value => setSubScore('score', subScore.id, value)"
          />
        </td>
        <td v-if="!hideSubComment">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="comment"
            :value="subScore.comment"
            @input="value => setSubScore('comment', subScore.id, value)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import GradeLevelSelector from "@/views/toefl/grading/sections/GradeLevelSelector";
import toeflApi from "@/apis/toefl.js";

export default {
  // testCategory: speaking_all | writing_independent | writing_integrated
  props: ["testCategory", "value", "hideSubComment", "testCategories"],
  components: {
    GradeLevelSelector
  },
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    }
  },
  data() {
    return {
      subScores: []
    };
  },
  async created() {
    let commentCategories = null;
    if (this.testCategories) {
      commentCategories = this.testCategories;
    } else {
      const { comment_categories: data } = await toeflApi.getSubScoreCategories(
        this.testCategory
      );
      commentCategories = data;
    }
    const findSubScore = categoryId =>
      (this.value || []).find(({ comment_category: { id } }) => id === categoryId);
    this.subScores = commentCategories.map(commentCategory => ({
      ...commentCategory,
      score: findSubScore(commentCategory.id)?.score || 0,
      comment: findSubScore(commentCategory.id)?.comment || ""
    }));
  },
  methods: {
    ridUseLessProp(subScores) {
      return subScores.map(subScore => ({
        order: subScore.order,
        comment_category_id: subScore.id,
        score: subScore.score,
        comment: subScore.comment
      }));
    },
    setSubScore(prop, id, value) {
      this.subScores = this.subScores.map(subScore => {
        if (subScore.id === id) {
          return { ...subScore, [prop]: value };
        }
        return subScore;
      });
      this.$emit("change", this.ridUseLessProp(this.subScores));
    }
  }
};
</script>

<style lang="scss" scoped>
.table .green-td {
  text-align: center;
  color: #fff;
  font-weight: bold;
  background-color: var(--themeColor);
}

.subsocre-table {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
}

tr > .sub-skill-name {
  text-align: left;
  font-weight: bolder;
  width: max-content;
}

tr > .level-score {
  width: 260px;
}
</style>