<template>
  <table class="table table-bordered subsocre-table">
    <thead>
      <tr>
        <th class="green-td">
          Skill
        </th>
        <th class="level-score green-td">
          Subscore
        </th>
        <th v-if="hasComment" class="green-td">
          Comments
        </th>
      </tr>
    </thead>
    <tbody>
      <tr :key="subScore.id" v-for="subScore in subScores">
        <td class="sub-skill-name">{{ subScore.title[lang] }}</td>
        <td :class="`score ${hasComment ? '' : 'stretch'}`">
          <div v-if="canScore">
            <div v-if="scored">
              <span v-if="subScore.score > 0">
                {{ scoreMapping[subScore.score] }}
              </span>
              <span v-else class="hasNotScore">-</span>
            </div>
            <div v-else class="unlockTag">
              {{ $t("message.unlockScored") }}
            </div>
          </div>
          <!-- <el-tooltip
            v-else
            class="item"
            effect="dark"
            :content="$t('toefl.balance.Get Comments for This Prompt')"
            placement="top"
          >
            <el-button type="warning" @click="$emit('unlock')">
              <i class="fas fa-lock"></i>
            </el-button>
          </el-tooltip> -->

          <template v-else>
            <el-button type="warning" @click="$emit('unlock')">
              <i class="fas fa-lock"></i>
              Human Grading
            </el-button>
          </template>
        </td>
        <td v-if="hasComment" class="comment">
          <div v-html="subScore.comment" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import toeflApi from "@/apis/toefl.js";

export default {
  // speaking_all writing_independent writing_integrated
  props: [
    "testCategory",
    "value",
    "canScore",
    "scored",
    "testCategories",
    "suggest_type"
  ],
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    hasComment() {
      return this.subScores.some(
        ({ comment }) => comment !== "" && this.suggest_type === "detail"
      );
    },
    scoreMapping() {
      return {
        1: "E",
        2: "D",
        3: "C",
        4: "B",
        5: "A"
      };
    }
  },
  data() {
    return {
      subScores: []
    };
  },
  async mounted() {
    let commentCategories = null;
    if (this.testCategories) {
      commentCategories = this.testCategories;
    } else {
      const { comment_categories: data } = await toeflApi.getSubScoreCategories(
        this.testCategory
      );
      commentCategories = data;
    }
    const findSubScore = categoryId =>
      (this.value || []).find(
        ({ comment_category: { id } }) => id === categoryId
      );
    this.subScores = commentCategories.map(commentCategory => ({
      ...commentCategory,
      score: findSubScore(commentCategory.id)?.score || 0,
      comment: findSubScore(commentCategory.id)?.comment || ""
    }));
  },
  methods: {
    ridUseLessProp(subScores) {
      return subScores.map(subScore => ({
        order: subScore.order,
        comment_category_id: subScore.id,
        score: subScore.score,
        comment: subScore.comment
      }));
    },
    setSubScore(prop, id, value) {
      this.subScores = this.subScores.map(subScore => {
        if (subScore.id === id) {
          return { ...subScore, [prop]: value };
        }
        return subScore;
      });
      this.$emit("change", this.ridUseLessProp(this.subScores));
    }
  }
};
</script>

<style lang="scss" scoped>
.table .green-td {
  text-align: center;
  color: #fff;
  font-weight: bold;
  background-color: var(--themeColor);
}

.subsocre-table {
  // margin-left: 15px;
  // margin-right: 15px;
  margin-top: 20px;
  // padding: 1rem;
}

tr > .sub-skill-name {
  text-align: left;
  font-weight: bolder;
  width: 200px;
}

tr > .score {
  color: #ff8920;
  font-size: 24px;
  font-weight: bold;
  width: 160px;
}

.hasNotScore {
  color: #000000;
  font-weight: normal;
}

tr > .stretch {
  width: auto;
}

tr > .comment {
  text-align: left;
}

.unlockTag {
  background-color: #ecf5ff;
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #409eff;
  border: 1px solid #d9ecff;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  font-weight: normal;
}
</style>
