<template>
  <div v-if="!isPro" class="adsByGoogle">
    <ins
      ref="adsbygoogle"
      class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-3412946985829139"
      data-ad-slot="2724307086"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </ins>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  computed:{
    isPro() {
      return this.$store.getters["user/getIsPro"];
    }
  },
  mounted(){
    (this.$refs.adsbygoogle = window.adsbygoogle || []).push({});
  }
};
</script>

<style lang="scss" scoped>
.adsByGoogle {
  margin: 20px 0px;
}
</style>
