<template>
  <div class="row cover">
    <div v-show="!showPassage" class="col-sm-6 cover-child question-box">
      <div class="cover-left">
        <div class="question" style="opacity: 1;">
          <div class="question-title" v-if="isBrowse">
            <span
              v-for="(title, index) in question_tags"
              @click="viewBrowse(index)"
              :key="index"
              :class="{
                active: question_detail.question_order === title.order,
                view_question: true,
                wrong: title.is_correct == 0
              }"
            >
              {{ title.order }}
            </span>
          </div>
          <div class="question-title" v-else>
            <span
              v-for="(title, index) in question_tags"
              @click="viewResolve(title.toefl_question_id)"
              :key="index"
              :class="{
                active: question_detail.question_order === title.order,
                view_question: true,
                wrong: title.is_correct == null || title.is_correct == 0
              }"
            >
              {{ title.order }}
            </span>
          </div>
        </div>
        <div class="question">
          <div class="resolve_content">
            <div class="title">
              {{ $t("pageTitle.Question") }}
              <EditListening
                v-if="isAdmin"
                :question_detail="question_detail"
                type="question"
              />
            </div>
          </div>

          <div class="exp">
            <div class="q_tags">
              <el-tag :key="tag.id" v-for="tag in question_detail.tags">
                {{ tag.name[$store.getters["user/langShortValue"]] }}
              </el-tag>
            </div>
            <div
              v-if="
                question_detail.content.repeat_audio &&
                  question_detail.content.repeat_audio !== ''
              "
            >
              <!-- <span class="btn play"><i class="far fa-play-circle"></i></span> -->
              <audio
                :src="question_detail.content.repeat_audio"
                controls
                controlsList="nodownload"
              ></audio>
            </div>
            <h4 class="q_title" v-html="question_detail.question"></h4>
            <template v-if="isBrowse">
              <div class="options" v-if="question_detail.type == 'default'">
                <div
                  class="radio"
                  v-for="option in question_detail.options"
                  :key="option.id"
                >
                  <div :class="showAlert(option.id)">
                    <label :for="option.id">{{ option.title }}</label>
                  </div>
                </div>
              </div>
              <div class="options" v-if="question_detail.type == 'checkbox'">
                <div
                  class="radio"
                  v-for="option in question_detail.options"
                  :key="option.id"
                >
                  <div
                    :class="
                      showCheckbox(
                        option.id,
                        question_detail.user_answers,
                        question_detail.answers[0].answers
                      )
                    "
                  >
                    <label :for="option.id">{{ option.title }}</label>
                  </div>
                </div>
              </div>
              <div class="options" v-if="question_detail.type == 'sort'">
                <div
                  class="radio"
                  v-for="option in question_detail.options"
                  :key="option.id"
                >
                  <div class="alert alert-success">
                    <label :for="option.id">{{ option.title }}</label>
                  </div>
                </div>
              </div>
              <div
                class="options"
                v-if="question_detail.type == 'multiple_choice'"
              >
                <table class="table table-bordered">
                  <tbody>
                    <tr
                      v-for="(option, index) in question_detail.content.options"
                      :key="index"
                    >
                      <td style="vertical-align: middle;">
                        {{ option }}
                      </td>
                      <td
                        v-for="(i, iIndex) in lines"
                        :key="iIndex"
                        :class="
                          showAlert(
                            question_detail.options[index * lines + iIndex]
                              ? question_detail.options[index * lines + iIndex]
                                  .id
                              : 0
                          )
                        "
                      >
                        <div class="radio">
                          <div>
                            <label>{{
                              question_detail.options[index * lines + iIndex]
                                ? question_detail.options[
                                    index * lines + iIndex
                                  ].title
                                : ""
                            }}</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="options" v-if="question_detail.type == 'default'">
                <div
                  class="radio"
                  v-for="option in question_detail.options"
                  :key="option.id"
                >
                  <div
                    class="alert alert-success"
                    v-if="
                      isCorrect(
                        option.id,
                        question_detail.user_answers,
                        question_detail.is_correct
                      )
                    "
                  >
                    <label :for="option.id">{{ option.title }}</label>
                  </div>
                  <div
                    class="alert alert-danger"
                    v-else-if="
                      isError(
                        option.id,
                        question_detail.user_answers,
                        question_detail.is_correct
                      )
                    "
                  >
                    <label :for="option.id">{{ option.title }}</label>
                  </div>
                  <div v-else>
                    <div
                      :class="
                        question_detail.answers[0].answers.includes(option.id)
                          ? 'alert-success alert'
                          : 'alert'
                      "
                    >
                      <label :for="option.id">{{ option.title }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="options" v-if="question_detail.type == 'checkbox'">
                <div
                  class="radio"
                  v-for="option in question_detail.options"
                  :key="option.id"
                >
                  <div
                    :class="
                      showCheckbox(
                        option.id,
                        question_detail.user_answers,
                        question_detail.answers[0].answers
                      )
                    "
                  >
                    <label :for="option.id">{{ option.title }}</label>
                  </div>
                </div>
              </div>
              <div class="options" v-if="question_detail.type == 'sort'">
                <div
                  class="radio"
                  v-for="option in question_detail.options"
                  :key="option.id"
                >
                  <div class="alert alert-success">
                    <label :for="option.id">{{ option.title }}</label>
                  </div>
                </div>
              </div>
              <div
                class="options"
                v-if="question_detail.type == 'multiple_choice'"
              >
                <table class="table table-bordered">
                  <tbody>
                    <tr
                      v-for="(option, index) in question_detail.content.options"
                      :key="index"
                    >
                      <td style="vertical-align: middle;">{{ option }}</td>
                      <td v-for="(i, iIndex) in lines" :key="iIndex">
                        <div
                          class="radio"
                          v-if="question_detail.options[index * lines + iIndex]"
                        >
                          <div>
                            <label>{{
                              question_detail.options[index * lines + iIndex]
                                .title
                            }}</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="answer_content">
                <div class="answers">
                  My Answer:
                  <b
                    v-html="
                      getMyAnswer(
                        question_detail.user_answer_title,
                        question_detail.answer_title,
                        question_detail.is_correct
                      )
                    "
                  ></b>
                </div>
                <div class="correctAnswer">
                  Correct Answer:
                  <b
                    v-html="getCorrectAnswer(question_detail.answer_title)"
                  ></b>
                </div>
                <div class="clear"></div>
              </div>
            </template>
          </div>
        </div>
        <div class="question">
          <div class="resolve_content">
            <div class="title">
              {{ $t("pageTitle.explanation") }}
              <EditListening
                v-if="isAdmin"
                :question_detail="question_detail"
                type="explanation"
              />
            </div>
            <div class="exp">
              <span
                v-if="lang === 'ZH-TW' || lang === 'EN-US'"
                v-html="traditionalized(replaceLine(question_detail.exp), lang)"
              >
              </span>
              <span v-else v-html="replaceLine(question_detail.exp)"></span>
            </div>
            <el-dialog
              title="Explanation-Edit"
              :visible.sync="editExplanation"
              width="80%"
            >
              <div>
                <EditExplanation :question_detail="question_detail" />
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="editExplanation = false">Cancel</el-button>
                <el-button type="primary" @click="editExplanation = false">
                  Save
                </el-button>
              </span>
            </el-dialog>
          </div>
        </div>
        <GoogleAd />
      </div>
    </div>
    <div
      :class="{
        'col-sm-12': showPassage,
        'col-sm-6': !showPassage,
        'cover-child': true,
        'passage-box': true
      }"
    >
      <div class="cover-left">
        <div class="question">
          <div class="resolve_content">
            <div class="title">
              {{ $t("pageTitle.Audio") }}
              <!-- <EditListening
                v-if="isAdmin"
                :question_detail="question_detail"
                type="audio"
              /> -->
            </div>
          </div>
          <div class="exp">
            <div class="audio">
              <audio
                style="width:100%"
                :src="question_detail.chapter_content.audio"
                controls
                controlsList="nodownload"
              ></audio>
            </div>
          </div>
        </div>
        <div class="question">
          <div class="resolve_content">
            <div class="title">
              {{ $t("pageTitle.Passage") }}
              <EditListening
                v-if="isAdmin"
                :question_detail="question_detail"
                type="passage"
              />
            </div>
          </div>
          <div class="exp">
            <h2 class="text-center">{{ question_detail.chapter_title }}</h2>
            <div
              id="passage"
              v-html="setPassage(question_detail.chapter_content.text)"
            ></div>
          </div>
        </div>
        <div class="open" @click="showPassage = !showPassage">
          <div class="text" v-show="!showPassage">
            <i class="fas fa-chevron-circle-left"></i>
          </div>
          <div class="text" v-show="showPassage">
            <i class="fas fa-chevron-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import role from "@/mixins/role.js";
import ToeflMixin from "@/mixins/toefl.js";
import Translate from "@/mixins/translate.js";
import GoogleAd from "@/views/ad/GoogleAd";
import EditListening from "@/views/toefl/toeflResolve/Edit/Listening";

export default {
  props:["question_detail", "question_tags", "title", "lang", "isBrowse"],
  mixins: [ToeflMixin, Translate, role],
  components: {
    GoogleAd,
    EditListening
  },
  computed:{
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    lines(){
      let lines = 2;
      if(this.question_detail.options && this.question_detail.content.options){
        lines = this.question_detail.options.length / this.question_detail.content.options.length;
      }
      return Math.ceil(lines);
    }
  },
  data() {
    return {
      showPassage: false,
      editQuestion: false,
      editPassage: false,
      editExplanation: false,
      editAudio: false
    };
  },
  watch: {},

  mounted() {},

  methods: {
    showAlert(id) {
      if(this.question_detail.answers[0].answers.includes(id)) {
        return "alert-success alert";
      } else if(this.question_detail.answers[1] && this.question_detail.answers[1].answers.includes(id)) {
        return "alert-success alert alert-blue";
      } else {
        return "alert";
      }
    },
    setPassage(passage) {
      let newPassage="";

      return passage;
    },
    isCorrect(answers,user_answers, isCorrect) {
      let correct = false;
      if(user_answers) {
        if(isCorrect === 1){
          if(user_answers.includes(answers)){
            correct = true;
          }else{
            correct = false;
          };
        }
      }
      return correct;
    },
    isError(answers,user_answers, isCorrect) {
      let error = false;
      if(user_answers) {
        if(isCorrect === 0){
          if(user_answers.includes(answers) && !this.question_detail.answers[0].answers.includes(answers) ){
            error = true;
          };
        }
      }
      return error;
    },
    showCheckbox(id,answers, canswers){
      if(canswers.includes(id)){
        return "alert alert-success";
      }else {
        let error = [];
        if(answers){
          answers.forEach(element => {
            if(!canswers.includes(element)){
              error.push(element);
            };
          });
          if(error.includes(id)){
            return "alert alert-danger";
          }else {
            return "alert";
          }
        }else {
          return "alert";
        }
      }
    },
    viewResolve(question_id) {
      this.$parent.viewResolve(question_id);
    },
    viewBrowse(index) {
      this.$parent.viewBrowse(index);
    }
  }
};
</script>

<style scoped src="@/views/toefl/toeflResolve/style.css"></style>
<style scoped>
::v-deep #passage br {
  content: "A" !important;
  display: block !important;
  margin-bottom: 1.5em !important;
}
.play {
  font-size: 30px;
  color: var(--themeColor);
}
.answer_content .answers {
  margin-right: 20px;
}
.cover {
  margin: 0;
}
.passage {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
}
.question {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
}
.passage-box {
  padding-left: 40px;
  padding-right: 0;
  background: white;
}
.question-box {
  padding-left: 0;
  padding-right: 20px;
  background: white;
}
.open {
  width: 40px;
  height: 70px;
  padding: 20px 0;
  background-color: #f2f3f5;
  font-size: 22px;
  top: 50%;
  transform: translateY(-35px);
  left: 0px;
  border-radius: 5px 0 0 5px;
}
.passage {
  background: #f2f3f5;
}
</style>
