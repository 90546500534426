<template>
  <div class="row cover">
    <div
      :class="{
        'col-sm-12': showPassage,
        'col-sm-6': !showPassage,
        'cover-child': true,
        'passage-box': true
      }"
    >
      <div class="cover-left">
        <template v-if="question_detail.type == 'independent_new'">
          <div class="question">
            <div class="resolve_content">
              <div class="title">
                {{ $t("pageTitle.Question") }}
                <EditWriting
                  v-if="isAdmin"
                  type="independent_new"
                  :question_detail="question_detail"
                />
              </div>
              <h6 class="article">
                <div class="q_tags">
                  <el-tag :key="tag.id" v-for="tag in question_detail.tags">
                    {{ tag.name[$store.getters["user/langShortValue"]] }}
                  </el-tag>
                </div>
                <div>
                  <p>
                    <b>Instructions: </b>
                    <!-- Your professor is teaching a class on business.  -->
                    Write a post responding to the professor's question. In your
                    response you should:
                  </p>
                  <ul class="ml-4">
                    <li>express and support your personal opinion</li>
                    <li>make a contribution to the discussion</li>
                  </ul>
                  <p>An effective response will contain at least 100 words.</p>
                </div>
                <div class="text-center">
                  <div class="">
                    <el-avatar
                      :size="180"
                      src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/images/user/Dr.png"
                    ></el-avatar>
                  </div>
                  <h4>Dr.Diaz</h4>
                </div>
                <p
                  class="mt-3 mb-4"
                  v-html="question_detail.content.professor"
                ></p>
                <div class="speaker mb-4">
                  <div class="text-center">
                    <div>
                      <el-avatar
                        :size="90"
                        src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/images/user/Karen.png"
                      ></el-avatar>
                    </div>
                    <h6>Karen</h6>
                  </div>
                  <div
                    class="speaker-info"
                    v-html="question_detail.content.female"
                  ></div>
                </div>
                <div class="speaker">
                  <div class="text-center">
                    <div>
                      <el-avatar
                        :size="90"
                        src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/images/user/Brad.png"
                      ></el-avatar>
                    </div>
                    <h6>Brad</h6>
                  </div>
                  <div
                    class="speaker-info"
                    v-html="question_detail.content.male"
                  ></div>
                </div>
              </h6>
            </div>
          </div>
        </template>
        <template v-if="question_detail.type == 'independent' || question_detail.type == 'integrated'">
          <div class="question">
            <div class="resolve_content">
              <div class="title">
                {{ $t("pageTitle.Directions") }}
                <EditWriting
                  v-if="isAdmin"
                  type="directions"
                  :question_detail="question_detail"
                />
              </div>
              <h6 class="article" v-html="question_detail.question"></h6>
            </div>
          </div>
          <div class="question">
            <div class="resolve_content">
              <div class="title">
                {{ $t("pageTitle.Question") }}
                <EditWriting
                  v-if="isAdmin"
                  type="question"
                  :question_detail="question_detail"
                />
              </div>
              <h6 class="article">
                <div class="q_tags">
                  <el-tag :key="tag.id" v-for="tag in question_detail.tags">
                    {{ tag.name[$store.getters["user/langShortValue"]] }}
                  </el-tag>
                </div>
                <div v-html="question_detail.content.article" />
              </h6>
            </div>
          </div>
        </template>
        <div class="question" v-if="question_detail.type == 'integrated'">
          <div>
            <div class="resolve_content">
              <div class="title">
                {{ $t("pageTitle.Audio") }}
                <EditWriting
                  v-if="isAdmin"
                  type="audio"
                  :question_detail="question_detail"
                />
              </div>
            </div>
            <audio
              :src="question_detail.content.audio"
              controls
              controlsList="nodownload"
            ></audio>
            <h6>
              <div
                class="article"
                v-html="replaceLine(question_detail.content.audio_text)"
              ></div>
            </h6>
          </div>
        </div>
        <div class="open" @click="showPassage = !showPassage">
          <div class="text" v-show="!showPassage">
            <i class="fas fa-chevron-circle-right"></i>
          </div>
          <div class="text" v-show="showPassage">
            <i class="fas fa-chevron-circle-left"></i>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!showPassage" class="col-sm-6 cover-child question-box">
      <div class="cover-left">
        <div v-if="!isBrowse" class="question">
          <template>
            <div class="resolve_content">
              <div class="title">{{ $t("pageTitle.My Answer") }}</div>
              <div class="exp">
                <div>
                  <div
                    v-if="
                      question_detail.user_answers &&
                        question_detail.user_answers[0]
                    "
                    v-html="setText(question_detail.user_answers[0])"
                    style="margin-bottom:10px"
                  ></div>
                  <div
                    class="ckeditor"
                    v-if="
                      examInfo.can_suggest === 1 || examInfo.can_score === 1
                    "
                  >
                    <div v-if="examInfo.suggest_type === 'outline'">
                      <el-divider content-position="left">
                        <b>{{ $t("GradingSamples.Simple Grading") }}</b>
                      </el-divider>
                    </div>
                    <div v-else-if="examInfo.suggest_type === 'detail'">
                      <el-divider content-position="left">
                        <b class="vip">
                          <el-tag type="warning" effect="plain">
                            <i class="fas fa-chess-queen"></i>
                            VIP
                          </el-tag></b
                        ><b>{{
                          $t("GradingSamples.VIP Grading")
                        }}</b></el-divider
                      >
                    </div>
                    <div v-else>
                      <el-divider content-position="left">
                        <b>Grades and Comments</b>
                      </el-divider>
                    </div>
                    <div style="margin-bottom:20px">
                      <div v-if="isAdmin">
                        <div
                          v-if="examInfo.can_score === 1"
                          class="d-flex align-items-center"
                        >
                          <b>Response Score:&nbsp;</b>
                          <GradeLevelSelector
                            section="writing"
                            :value="question_detail.score"
                            @change="score => (question_detail.score = score)"
                          />
                        </div>
                        <div class="col-sm-12">
                          <GradingSubScoreTable
                            v-if="examInfo.can_score === 1"
                            :value="
                              (question_detail.answer_score_detail || {})
                                .detail_score_comments
                            "
                            :testCategory="`writing_${question_detail.type}`"
                            :hideSubComment="!examInfo.can_suggest"
                            @change="
                              subScores =>
                                (question_detail.answer_score_detail = {
                                  ...(question_detail.answer_score_detail ||
                                    {}),
                                  detail_score_comments: subScores
                                })
                            "
                          />
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="examInfo.can_score === 1">
                          <b
                            style="line-height:41px"
                            v-if="examInfo.is_scored === 1"
                          >
                            Score:
                            <span class="text-warning" style="font-size:18px">
                              {{ question_detail.score }}</span
                            >
                          </b>
                          <div v-else>
                            <el-button
                              type="success"
                              style="width:100%"
                              size="small"
                              disabled
                              plain
                              round
                            >
                              {{ $t("message.unlockScored") }}
                            </el-button>
                          </div>
                        </div>
                        <!-- <el-button
                          v-else
                          type="warning"
                          size="small"
                          @click="$parent.getAlert()"
                        >
                          {{ $t("toefl.balance.Show Score") }}
                        </el-button> -->
                        <template v-else>
                          <el-button type="warning" @click="$parent.getAlert()">
                            {{ $t("toefl.balance.Show Score") }}
                          </el-button>
                          <el-button type="warning" @click="$parent.getAlert()">
                            {{ $t("toefl.balance.Show Score") }}
                          </el-button>
                        </template>
                        <div class="col-sm-12">
                          <SubScoreTable
                            :value="
                              (question_detail.answer_score_detail || {})
                                .detail_score_comments
                            "
                            :testCategory="`writing_${question_detail.type}`"
                            :canScore="examInfo.can_score"
                            :scored="examInfo.is_scored"
                            @unlock="$parent.getAlert()"
                          />
                        </div>
                      </div>
                      <div>
                        <div v-if="isAdmin">
                          <template v-if="examInfo.can_suggest === 1">
                            <div class="overall-comments">
                              <b>Overall Comments:&nbsp;</b>
                            </div>
                            <el-input
                              class="transcript"
                              type="textarea"
                              :rows="8"
                              placeholder="Comments"
                              v-model="question_detail.suggest"
                              :disabled="false"
                            >
                            </el-input>
                          </template>
                          <div
                            class="text-left mt-3"
                            v-if="
                              examInfo.can_suggest === 1 &&
                                examInfo.suggest_type === 'detail'
                            "
                          >
                            <el-input
                              v-model="
                                (question_detail.answer_score_detail || {})
                                  .overall_comment_url
                              "
                            >
                              <template slot="prepend">
                                <i class="fas fa-file-alt"></i> <b>Doc URL:</b>
                              </template>
                            </el-input>
                          </div>
                          <div class="mt-4">
                            <el-button
                              @click="saveTranscriptAndNotify"
                              size="mini"
                              type="success"
                            >
                              <i class="fa fa-save"></i> Save and send notification
                            </el-button>
                          </div>
                        </div>
                        <div v-else>
                          <div style="margin-top:40px">
                            <div class="overall-comments">
                              <b>Overall Comments:&nbsp;</b>
                            </div>
                          </div>
                          <div v-if="examInfo.can_suggest === 1">
                            <div
                              v-if="examInfo.is_scored === 1"
                              style="word-break: normal !important;"
                              v-html="question_detail.suggest"
                            ></div>
                            <div v-else>
                              <el-button type="success" disabled plain round>
                                {{ $t("message.pendingComments") }}
                              </el-button>
                            </div>
                            <div
                              class=" mt-3"
                              v-if="
                                examInfo.can_suggest === 1 &&
                                  examInfo.suggest_type === 'detail' &&
                                  (question_detail.answer_score_detail || {})
                                    .overall_comment_url
                              "
                            >
                              <div class="overall-comments score-title">
                                <b>Doc URL:</b>
                              </div>
                              <a
                                :href="
                                  question_detail.answer_score_detail
                                    .overall_comment_url
                                "
                                ><i class="fas fa-file-alt"></i> <b>Doc.</b></a
                              >
                            </div>
                          </div>
                          <el-button
                            v-else
                            type="warning"
                            @click="$parent.getAlert()"
                          >
                            {{
                              $t("toefl.balance.Get Comments for This Prompt")
                            }}
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- <template
          v-if="question_detail.chapter_order === 1 && writingQuestionLesson"
        >
          <div class="tips">
            <div class="adImageWrapper">
              <img class="adImage" :src="writingQuestionLesson.cover" alt="" />
            </div>
            <h5>
              <a
                :href="`${baseDomainPath}lessons?v=${WRITING_QUESTION_LESSON_ID}`"
                target="_blank"
              >
                {{ $t("message.customLessonWrite1AdTitle") }}
              </a>
            </h5>
          </div>
        </template> -->
        <div class="question" v-if="question_detail.exp">
          <div class="resolve_content">
            <div class="title">
              {{ $t("pageTitle.Sample Response") }}
              <EditWriting
                v-if="isAdmin"
                type="explanation"
                :question_detail="question_detail"
              />
            </div>
            <div class="exp exp-response">
              <div
                v-if="lang === 'ZH-TW' || lang === 'EN-US'"
                v-html="traditionalized(replaceLine(question_detail.exp), lang)"
              ></div>
              <div v-else v-html="replaceLine(question_detail.exp)"></div>
            </div>
          </div>
        </div>
        <GoogleAd />
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Translate from "@/mixins/translate.js";
import role from "@/mixins/role.js";
import toefl from "@/mixins/toefl.js";
import TOEFL from "@/apis/toefl";
import lessonsApi from "@/apis/lessons";
import EditWriting from "@/views/toefl/toeflResolve/Edit/Writing";
import GradeLevelSelector from "@/views/toefl/grading/sections/GradeLevelSelector";
import GradingSubScoreTable from "@/views/toefl/grading/sections/SubScoreTable";
import SubScoreTable from "@/views/toefl/transcript/components/SubScoreTable";
import GoogleAd from "@/views/ad/GoogleAd";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  props:["question_detail", "answers", "title", "lang", "examInfo", "isBrowse"],
  mixins: [Translate, role, toefl],
  components: {
    EditWriting,
    GradeLevelSelector,
    GradingSubScoreTable,
    SubScoreTable,
    GoogleAd
  },
  created(){
  },
  computed:{
    TEST_CATEGORY() {
      return `writing_${this.question_detail.type}`;
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    myScore(){
      return this.question_detail.score;
    },
    mySuggest() {
      return this.question_detail.suggest;
    },
    WRITING_QUESTION_LESSON_ID() {
      return 2;
    },
    baseDomainPath() {
      return baseDomainPath;
    }
  },
  data() {
    return {
      editDirections: false,
      editQuestion: false,
      editPassage: false,
      editExplanation: false,
      editAudio: false,
      canEdit: false,
      showPassage: false,
      score_list: [1, 2, 3, 4, 5],
      score: 0,
      suggest: "",
      writingQuestionLesson: null,
      updateAnswers: []
    };
  },
  watch: {},

  async mounted() {
    const { lesson } = await lessonsApi.getLesson(this.WRITING_QUESTION_LESSON_ID);
    this.writingQuestionLesson = lesson;
  },

  methods: {
    setText(text) {
      return text.replace(/\n/g, "<br />");
    },
    getArr(arr) {
      arr.forEach(val => {
        let newVal = {
          id: val.user_exam_answer_id,
          score: val.score,
          suggest: val.suggest,
          answer_score_detail: {
            comment_type: this.TEST_CATEGORY,
            detail_score_comments:
               val.answer_score_detail?.detail_score_comments,
            overall_comment_url: val.answer_score_detail?.overall_comment_url
          }
        };
        this.updateAnswers.push(newVal);
      });
    },
    autoSaveTranscript() {
      if (!this.timer && this.isAdmin && !this.isBrowse) {
        this.timer = setTimeout(() => {
          this.saveTranscript();
          clearInterval(this.timer);
          this.timer = null;
        }, 2000);
      }
    },
    async saveTranscriptAndNotify() {
      await this.saveTranscript();
      this.sendEmail(this.$route.query.user_exam_id);
    },
    async sendEmail(user_exam_id) {
      try {
        await this.$confirm("Do you want to send a notification email?", "Saved!", {
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        });
        await TOEFL.sendEmail(user_exam_id);
        this.$message({
          message: "Success!",
          type: "success"
        });
      } catch (e) {
        // do nothing
      }
    },
    async saveTranscript(text = "") {
      this.canEdit = false;
      this.updateAnswers = [];
      this.getArr([this.question_detail]);
      await TOEFL.updateTranscript(this.$route.query.user_exam_id, {
        user_exam_answers: this.updateAnswers
      });
      this.$notify({
        title: text + "Success!",
        type: "success",
        position: "bottom-right",
        duration: "1000"
      });
    }
  }
};
</script>

<style scoped src="@/views/toefl/toeflResolve/style.css"></style>
<style scoped>
.speaking_resolve {
  margin-top: 30px;
}
.cover {
  margin: 0;
}
.passage {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
}
.question {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
}
.passage-box {
  padding-left: 0;
  padding-right: 40px;
  background: white;
}
.question-box {
  padding-left: 20px;
  padding-right: 0;
  background: white;
}
.open {
  width: 40px;
  height: 70px;
  padding: 20px 0;
  background-color: #f2f3f5;
  font-size: 22px;
  top: 50%;
  transform: translateY(-35px);
  right: 0px;
  border-radius: 0 5px 5px 0;
}
.article {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.vip {
  color: #f0ad4e;
}
.vip >>> .el-tag {
  margin-right: 8px;
  height: 20px;
  line-height: 18px;
  padding: 0 4px;
}

.ckeditor {
  margin-bottom: 20px;
}

::v-deep .exp-response > div > div {
  background-color: #fdf6ec !important;
  color: #e6a23c !important;
  padding: 8px 16px !important;
}

.tips {
  height: 112.5px;
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 20px;
  margin-bottom: 20px;
}
.tips h5 {
  font-size: clamp(0.8rem, 1.6vw, 1.5rem);
  color: var(--themeColor);
}

.adImageWrapper {
  width: 100%;
  position: relative;
}
.adImageWrapper > .adImage {
  width: 100%;
}
</style>
