var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row cover"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassage),expression:"!showPassage"}],staticClass:"col-sm-6 cover-child question-box"},[_c('div',{staticClass:"cover-left"},[_c('div',{staticClass:"question",staticStyle:{"opacity":"1"}},[(_vm.isBrowse)?_c('div',{staticClass:"question-title"},_vm._l((_vm.question_tags),function(title,index){return _c('span',{key:index,class:{
              active: _vm.question_detail.question_order === title.order,
              view_question: true,
              wrong: title.is_correct == 0
            },on:{"click":function($event){return _vm.viewBrowse(index)}}},[_vm._v(" "+_vm._s(title.order)+" ")])}),0):_c('div',{staticClass:"question-title"},_vm._l((_vm.question_tags),function(title,index){return _c('span',{key:index,class:{
              active: _vm.question_detail.question_order === title.order,
              view_question: true,
              wrong: title.is_correct == null || title.is_correct == 0
            },on:{"click":function($event){return _vm.viewResolve(title.toefl_question_id)}}},[_vm._v(" "+_vm._s(title.order)+" ")])}),0)]),_c('div',{staticClass:"question"},[_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.Question"))+" "),(_vm.isAdmin)?_c('EditListening',{attrs:{"question_detail":_vm.question_detail,"type":"question"}}):_vm._e()],1)]),_c('div',{staticClass:"exp"},[_c('div',{staticClass:"q_tags"},_vm._l((_vm.question_detail.tags),function(tag){return _c('el-tag',{key:tag.id},[_vm._v(" "+_vm._s(tag.name[_vm.$store.getters["user/langShortValue"]])+" ")])}),1),(
              _vm.question_detail.content.repeat_audio &&
                _vm.question_detail.content.repeat_audio !== ''
            )?_c('div',[_c('audio',{attrs:{"src":_vm.question_detail.content.repeat_audio,"controls":"","controlsList":"nodownload"}})]):_vm._e(),_c('h4',{staticClass:"q_title",domProps:{"innerHTML":_vm._s(_vm.question_detail.question)}}),(_vm.isBrowse)?[(_vm.question_detail.type == 'default')?_c('div',{staticClass:"options"},_vm._l((_vm.question_detail.options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[_c('div',{class:_vm.showAlert(option.id)},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])])])}),0):_vm._e(),(_vm.question_detail.type == 'checkbox')?_c('div',{staticClass:"options"},_vm._l((_vm.question_detail.options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[_c('div',{class:_vm.showCheckbox(
                      option.id,
                      _vm.question_detail.user_answers,
                      _vm.question_detail.answers[0].answers
                    )},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])])])}),0):_vm._e(),(_vm.question_detail.type == 'sort')?_c('div',{staticClass:"options"},_vm._l((_vm.question_detail.options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[_c('div',{staticClass:"alert alert-success"},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])])])}),0):_vm._e(),(_vm.question_detail.type == 'multiple_choice')?_c('div',{staticClass:"options"},[_c('table',{staticClass:"table table-bordered"},[_c('tbody',_vm._l((_vm.question_detail.content.options),function(option,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(" "+_vm._s(option)+" ")]),_vm._l((_vm.lines),function(i,iIndex){return _c('td',{key:iIndex,class:_vm.showAlert(
                          _vm.question_detail.options[index * _vm.lines + iIndex]
                            ? _vm.question_detail.options[index * _vm.lines + iIndex]
                                .id
                            : 0
                        )},[_c('div',{staticClass:"radio"},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.question_detail.options[index * _vm.lines + iIndex] ? _vm.question_detail.options[ index * _vm.lines + iIndex ].title : ""))])])])])})],2)}),0)])]):_vm._e()]:[(_vm.question_detail.type == 'default')?_c('div',{staticClass:"options"},_vm._l((_vm.question_detail.options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[(
                    _vm.isCorrect(
                      option.id,
                      _vm.question_detail.user_answers,
                      _vm.question_detail.is_correct
                    )
                  )?_c('div',{staticClass:"alert alert-success"},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])]):(
                    _vm.isError(
                      option.id,
                      _vm.question_detail.user_answers,
                      _vm.question_detail.is_correct
                    )
                  )?_c('div',{staticClass:"alert alert-danger"},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])]):_c('div',[_c('div',{class:_vm.question_detail.answers[0].answers.includes(option.id)
                        ? 'alert-success alert'
                        : 'alert'},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])])])])}),0):_vm._e(),(_vm.question_detail.type == 'checkbox')?_c('div',{staticClass:"options"},_vm._l((_vm.question_detail.options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[_c('div',{class:_vm.showCheckbox(
                      option.id,
                      _vm.question_detail.user_answers,
                      _vm.question_detail.answers[0].answers
                    )},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])])])}),0):_vm._e(),(_vm.question_detail.type == 'sort')?_c('div',{staticClass:"options"},_vm._l((_vm.question_detail.options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[_c('div',{staticClass:"alert alert-success"},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])])])}),0):_vm._e(),(_vm.question_detail.type == 'multiple_choice')?_c('div',{staticClass:"options"},[_c('table',{staticClass:"table table-bordered"},[_c('tbody',_vm._l((_vm.question_detail.content.options),function(option,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(option))]),_vm._l((_vm.lines),function(i,iIndex){return _c('td',{key:iIndex},[(_vm.question_detail.options[index * _vm.lines + iIndex])?_c('div',{staticClass:"radio"},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.question_detail.options[index * _vm.lines + iIndex] .title))])])]):_vm._e()])})],2)}),0)])]):_vm._e(),_c('div',{staticClass:"answer_content"},[_c('div',{staticClass:"answers"},[_vm._v(" My Answer: "),_c('b',{domProps:{"innerHTML":_vm._s(
                    _vm.getMyAnswer(
                      _vm.question_detail.user_answer_title,
                      _vm.question_detail.answer_title,
                      _vm.question_detail.is_correct
                    )
                  )}})]),_c('div',{staticClass:"correctAnswer"},[_vm._v(" Correct Answer: "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.getCorrectAnswer(_vm.question_detail.answer_title))}})]),_c('div',{staticClass:"clear"})])]],2)]),_c('div',{staticClass:"question"},[_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.explanation"))+" "),(_vm.isAdmin)?_c('EditListening',{attrs:{"question_detail":_vm.question_detail,"type":"explanation"}}):_vm._e()],1),_c('div',{staticClass:"exp"},[(_vm.lang === 'ZH-TW' || _vm.lang === 'EN-US')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.traditionalized(_vm.replaceLine(_vm.question_detail.exp), _vm.lang))}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.replaceLine(_vm.question_detail.exp))}})]),_c('el-dialog',{attrs:{"title":"Explanation-Edit","visible":_vm.editExplanation,"width":"80%"},on:{"update:visible":function($event){_vm.editExplanation=$event}}},[_c('div',[_c('EditExplanation',{attrs:{"question_detail":_vm.question_detail}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.editExplanation = false}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.editExplanation = false}}},[_vm._v(" Save ")])],1)])],1)]),_c('GoogleAd')],1)]),_c('div',{class:{
      'col-sm-12': _vm.showPassage,
      'col-sm-6': !_vm.showPassage,
      'cover-child': true,
      'passage-box': true
    }},[_c('div',{staticClass:"cover-left"},[_c('div',{staticClass:"question"},[_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.Audio"))+" ")])]),_c('div',{staticClass:"exp"},[_c('div',{staticClass:"audio"},[_c('audio',{staticStyle:{"width":"100%"},attrs:{"src":_vm.question_detail.chapter_content.audio,"controls":"","controlsList":"nodownload"}})])])]),_c('div',{staticClass:"question"},[_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.Passage"))+" "),(_vm.isAdmin)?_c('EditListening',{attrs:{"question_detail":_vm.question_detail,"type":"passage"}}):_vm._e()],1)]),_c('div',{staticClass:"exp"},[_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.question_detail.chapter_title))]),_c('div',{attrs:{"id":"passage"},domProps:{"innerHTML":_vm._s(_vm.setPassage(_vm.question_detail.chapter_content.text))}})])]),_c('div',{staticClass:"open",on:{"click":function($event){_vm.showPassage = !_vm.showPassage}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassage),expression:"!showPassage"}],staticClass:"text"},[_c('i',{staticClass:"fas fa-chevron-circle-left"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPassage),expression:"showPassage"}],staticClass:"text"},[_c('i',{staticClass:"fas fa-chevron-circle-right"})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }