<template>
  <div class="cover">
    <div class="box-top" :style="changeTop">
      <div
        :class="{
          'cover-child': true,
          'passage-box': true
        }"
      >
        <div class="passage">
          <!-- <h2 class="text-center">{{ question_detail.chapter_title }}</h2> -->
          <div v-if="question_detail.type == 'article_audio'">
            <div class="resolve_content">
              <div class="title">{{ $t("pageTitle.Passage") }}</div>
            </div>
            <h6 class="article" v-html="question_detail.content.reading"></h6>
            <hr />
          </div>
          <div
            v-if="
              question_detail.type == 'article_audio' ||
                question_detail.type == 'audio'
            "
          >
            <div>
              <div class="resolve_content">
                <div class="title">{{ $t("pageTitle.Audio") }}</div>
              </div>
              <audio
                :src="question_detail.content.talking_audio"
                controls
              ></audio>
              <h6>
                <div
                  class="article"
                  v-html="question_detail.content.talking_audio_text"
                ></div>
              </h6>
            </div>
            <hr />
          </div>
          <div class="resolve_content">
            <div class="title">Question:</div>
            <h6 class="article">
              <div class="q_tags">
                <el-tag :key="tag.id" v-for="tag in question_detail.tags">
                  {{ tag.name[$store.getters["user/langShortValue"]] }}
                </el-tag>
              </div>
              {{ question_detail.question }}
            </h6>
          </div>
          <div id="passage"></div>
          <div class="open" @click="showPassage = !showPassage">
            <div class="text" v-show="!showPassage">
              <i class="fas fa-chevron-circle-right"></i>
            </div>
            <div class="text" v-show="showPassage">
              <i class="fas fa-chevron-circle-left"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="change-box" :style="changeCenter" v-show="boxType === 0">
        <el-button
          type="primary"
          size="small"
          style="width:100%"
          @click="boxType = 1"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 2"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </div>
      <div class="change-box" :style="changeCenter" v-show="boxType === 1">
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 3"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 0"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </div>
      <div class="change-box" :style="changeCenter" v-show="boxType === 2">
        <el-button
          v-show="boxType === 2"
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 0"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          v-show="boxType === 2"
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 4"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </div>
      <div class="change-box" :style="changeCenter" v-show="boxType === 3">
        <el-button
          type="info"
          size="small"
          style="width:100%;margin:0"
          disabled
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 1"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </div>
      <div class="change-box" :style="changeCenter" v-show="boxType === 4">
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 2"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="info"
          size="small"
          style="width:100%;margin:0"
          disabled
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </div>
    </div>
    <div class="box-bottom" :style="changeBottom">
      <div v-show="!showPassage" class="cover-child question-box">
        <div class="passage" style="opacity: 1;">
          <div class="speaking_resolve">
            <template v-if="!isBrowse">
              <div class="resolve_content">
                <div class="title">{{ $t("pageTitle.My Answer") }}</div>
                <div class="exp">
                  <div style="margin-bottom:20px">
                    <div v-if="isAdmin">
                      <div v-if="examInfo.can_score === 1">
                        <b style="line-height:41px">
                          Score:
                        </b>
                        <el-select
                          v-model="question_detail.score"
                          placeholder="Score"
                          :disabled="false"
                        >
                          <el-option label="Please select a score" :value="0">
                            Please select a score
                          </el-option>
                          <el-option
                            v-for="item in score_list"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="examInfo.can_score === 1">
                        <b
                          style="line-height:41px"
                          v-if="examInfo.is_scored === 1"
                        >
                          Score:
                          <span class="text-warning" style="font-size:18px">
                            {{ question_detail.score }}</span
                          >
                        </b>
                        <div v-else>
                          <el-button
                            type="success"
                            style="width:100%"
                            disabled
                            plain
                            round
                          >
                            {{ $t("message.unlockScored") }}
                          </el-button>
                        </div>
                      </div>
                      <el-button
                        v-else
                        type="warning"
                        @click="$parent.getAlert()"
                        style="width:100%"
                      >
                        {{ $t("toefl.balance.Show Score") }}
                      </el-button>
                    </div>
                  </div>
                  <div v-if="hasAnswer">
                    <audio
                      :src="question_detail.user_answers[0]"
                      controls
                    ></audio>
                  </div>
                  <div style="margin-top:20px;">
                    <el-divider content-position="left">{{ $t("toefl.balance.Grader Comments") }}</el-divider>
                  </div>
                  <div>
                    <div v-if="isAdmin">
                      <el-input
                        class="transcript"
                        type="textarea"
                        :rows="8"
                        placeholder="Please enter content"
                        v-model="question_detail.suggest"
                        :disabled="false"
                      >
                      </el-input>
                      <el-button
                        @click="saveTranscriptAndNotify"
                        size="mini"
                        type="success"
                      >
                        <i class="fa fa-save"></i> Save and send email
                      </el-button>
                    </div>
                    <div v-else>
                      <div v-if="examInfo.can_suggest === 1">
                        <div
                          v-if="examInfo.is_scored === 1"
                          style="word-break: normal !important;"
                        >
                          {{ question_detail.suggest }}
                        </div>
                        <div v-else>
                          <el-button type="success" disabled plain round>
                            {{ $t("message.pendingComments") }}
                          </el-button>
                        </div>
                      </div>
                      <el-button
                        v-else
                        type="warning"
                        @click="$parent.getAlert()"
                        style="width:100%"
                      >
                        {{ $t("toefl.balance.Get Comments for This Prompt") }}
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </template>
            <!-- <template
              v-if="
                question_detail.chapter_order === 2 && speakingQuestionLesson
              "
            >
              <div class="tips">
                <div class="adImageWrapper">
                  <img class="adImage" :src="speakingQuestionLesson.cover" alt="" />
                </div>
                <h5>
                  <a :href="`${baseDomainPath}lessons?v=${SPEAKING_QUESTION_LESSON_ID}`" target="_blank">
                    {{ $t("message.customLessonSpeak2AdTitle") }}
                  </a>
                </h5>
              </div>
              <hr />
            </template> -->
            <div class="resolve_content">
              <div class="title">{{ $t("pageTitle.Sample Response") }}</div>
              <div class="exp">
                <div
                  v-if="lang === 'ZH-TW' || lang === 'EN-US'"
                  v-html="traditionalized(replaceLine(question_detail.exp), lang)"
                ></div>
                <div v-else v-html="replaceLine(question_detail.exp)"></div>
              </div>
            </div>
            <GoogleAd />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Translate from "@/mixins/translate.js";
import role from "@/mixins/role.js";
import TOEFL from "@/apis/toefl";
import lessonsApi from "@/apis/lessons";
import GoogleAd from "@/views/ad/GoogleAd";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  props:["question_detail", "answers", "title", "lang", "examInfo","isBrowse"],
  components: {
    GoogleAd
  },
  mixins: [Translate, role],
  created(){
  },
  computed:{
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    hasAnswer() {
      return (this.question_detail.user_answers !== null && this.question_detail.user_answers.length > 0);
    },
    changeTop() {
      let str = "";
      if (this.boxType === 0) {
        str = "height: 47%";
      }
      if (this.boxType === 1) {
        str = "height: 69%";
      }
      if (this.boxType === 2) {
        str = "height: 25%";
      }
      if (this.boxType === 3) {
        str = "height: 94%";
      }
      if (this.boxType === 4) {
        str = "display:none";
      }
      return str;
    },
    changeBottom() {
      let str = "";
      if (this.boxType === 0) {
        str = "height: 47%";
      }
      if (this.boxType === 1) {
        str = "height: 25%";
      }
      if (this.boxType === 2) {
        str = "height: 69%";
      }
      if (this.boxType === 3) {
        str = "display:none";
      }
      if (this.boxType === 4) {
        str = "height: 94%";
      }
      return str;
    },
    changeCenter() {
      let str = "height: 6%";
      return str;
    },
    SPEAKING_QUESTION_LESSON_ID() {
      return 1;
    },
    baseDomainPath() {
      return baseDomainPath;
    }
  },
  data() {
    return {
      canEdit: false,
      showPassage: false,
      score_list: [1, 2, 3, 4],
      score: 0,
      suggest: "",
      speakingQuestionLesson: null,
      boxType: 0
    };
  },
  watch: {
    question_detail: {
      handler() {
        if (!this.timer && this.isAdmin) {
          this.timer = setTimeout(() => {
            this.saveTranscript();
            clearInterval(this.timer);
            this.timer = null;
          }, 2000);
        }
      },
      deep: true
    }
  },

  async mounted() {
    const { lesson } = await lessonsApi.getLesson(this.SPEAKING_QUESTION_LESSON_ID);
    this.speakingQuestionLesson = lesson;
  },

  methods: {
    getArr(arr) {
      arr.forEach(val => {
        let newVal = {
          id: val.user_exam_answer_id,
          score: val.score,
          suggest: val.suggest
        };
        this.updateAnswers.push(newVal);
      });
    },
    async saveTranscriptAndNotify() {
      await this.saveTranscript();
      this.sendEmail(this.$route.query.user_exam_id);
    },
    async sendEmail(user_exam_id) {
      try {
        await this.$confirm("Do you want to send a notification email?", "Saved!", {
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        });
        await TOEFL.sendEmail(user_exam_id);
        this.$message({
          message: "Success!",
          type: "success"
        });
      } catch (e) {
        // do nothing
      }
    },
    async saveTranscript(text = "") {
      this.canEdit = false;
      this.updateAnswers = [];
      this.getArr([this.question_detail]);
      await TOEFL.updateTranscript(this.$route.query.user_exam_id, {
        user_exam_answers: this.updateAnswers
      });
      this.$notify({
        title: text + "Success!",
        type: "success",
        position: "bottom-right",
        duration: "1000"
      });
    }
  }
};
</script>

<style scoped src="@/views/toefl/toeflResolve/style.css"></style>
<style scoped>
.passage {
  height: 100%;
  overflow: scroll;
  background-color: #f2f3f5;
  padding: 0px 0px 20px 0px;
  border-radius: 5px;
}
.article {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.transcript {
  margin-bottom: 20px;
}

.tips .img img {
  width: 100%;
}
.tips {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tips h5 {
  color: var(--themeColor);
}

.adImageWrapper {
  width: 100%;
  padding-right: 20px;
  position: relative;
}
.adImageWrapper > .adImage {
  width: 100%;
}
.open {
  display: none;
  width: 40px;
  height: 70px;
  padding: 20px 0;
  background-color: #f2f3f5;
  font-size: 22px;
  top: 50%;
  transform: translateY(-35px);
  right: 0px;
  border-radius: 0 5px 5px 0;
}
.passage {
  background-color: #f2f3f5;
  padding: 15px;
  border-radius: 5px;
}
.passage-box,
.question-box {
  padding-left: 0;
  padding-right: 0;
  background: white;
}
</style>
