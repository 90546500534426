<template>
  <div class="letter-level-btns">
    <el-button
      size="small"
      :class="gradeLevel.value === value ? 'selected' : ''"
      :key="gradeLevel.value"
      v-for="gradeLevel in gradeLevels"
      @click="() => $emit('change', gradeLevel.value)"
    >
      {{ gradeLevel.label }}
    </el-button>
  </div>
</template>
<script>
export default {
  /*
    levelType: "letter", "digits"
    section: "speaking", "writing"
  */
  props: ["levelType", "section", "value"],
  computed: {
    gradeLevels() {
      if (this.levelType === "letter") {
        return this.letters;
      }

      if (this.section === "speaking") {
        return this.speakingDigits;
      } else {
        return this.writingDigits;
      }
    },
    writingDigits() {
      return [
        { label: 5, value: 5 },
        { label: 4, value: 4 },
        { label: 3, value: 3 },
        { label: 2, value: 2 },
        { label: 1, value: 1 }
      ];
    },
    speakingDigits() {
      return [
        { label: 4, value: 4 },
        { label: 3, value: 3 },
        { label: 2, value: 2 },
        { label: 1, value: 1 }
      ];
    },
    letters() {
      return [
        { label: "A", value: 5 },
        { label: "B", value: 4 },
        { label: "C", value: 3 },
        { label: "D", value: 2 },
        { label: "E", value: 1 }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.letter-level-btns {
  display: flex;
  button {
    border: 1px solid var(--themeColor);
    color: var(--themeColor);
  }

  .selected {
    background: var(--themeColor);
    color: #ffffff;
  }
}
</style>