var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row cover"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassage),expression:"!showPassage"}],staticClass:"col-sm-6 cover-child question-box"},[_c('div',{staticClass:"cover-left"},[_c('div',{staticClass:"question",staticStyle:{"opacity":"1"}},[(_vm.isBrowse)?_c('div',{staticClass:"question-title"},_vm._l((_vm.question_tags),function(title,index){return _c('span',{key:index,class:{
              active: _vm.question_detail.question_order === title.order,
              view_question: true,
              wrong: title.is_correct == 0
            },on:{"click":function($event){return _vm.viewBrowse(index)}}},[_vm._v(" "+_vm._s(title.order)+" ")])}),0):_c('div',{staticClass:"question-title"},_vm._l((_vm.question_tags),function(title,index){return _c('span',{key:index,class:{
              active: _vm.question_detail.question_order === title.order,
              view_question: true,
              wrong: title.is_correct == null || title.is_correct == 0
            },on:{"click":function($event){return _vm.viewResolve(title.toefl_question_id)}}},[_vm._v(" "+_vm._s(title.order)+" ")])}),0)]),_c('div',{staticClass:"question"},[_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.Question"))+" "),(_vm.isAdmin)?_c('EditReading',{attrs:{"question_detail":_vm.question_detail,"type":"question"}}):_vm._e()],1)]),_c('div',{staticClass:"exp"},[_c('div',{staticClass:"q_tags"},_vm._l((_vm.question_detail.tags),function(tag){return _c('el-tag',{key:tag.id},[_vm._v(" "+_vm._s(tag.name[_vm.$store.getters["user/langShortValue"]])+" ")])}),1),(_vm.question_detail.type == 'click')?_c('h5',{staticClass:"q_title"},[_vm._v(" Look at the four squares [■] that indicate where the following sentence could be added to the passage. Where would the sentence best fit? Click on a square [■] to add the sentence to the passage. ")]):_c('h5',{staticClass:"q_title",domProps:{"innerHTML":_vm._s(_vm.question_detail.question)}}),(_vm.question_detail.type == 'click')?_c('p',{staticClass:"sentence",domProps:{"innerHTML":_vm._s(_vm.question_detail.question)}}):_vm._e(),(_vm.isBrowse)?[_c('div',{staticClass:"options"},_vm._l((_vm.question_detail.options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[_c('div',{class:_vm.showAlert(option.id)},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])])])}),0)]:[_c('div',{staticClass:"options"},_vm._l((_vm.question_detail.options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[(
                    _vm.isCorrect(
                      option.id,
                      _vm.question_detail.user_answers,
                      _vm.question_detail.is_correct
                    )
                  )?_c('div',{staticClass:"alert alert-success"},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])]):(
                    _vm.isError(
                      option.id,
                      _vm.question_detail.user_answers,
                      _vm.question_detail.is_correct
                    )
                  )?_c('div',{staticClass:"alert alert-danger"},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])]):_c('div',[_c('div',{class:_vm.question_detail.answers[0].answers.includes(option.id)
                        ? 'alert-success alert'
                        : 'alert'},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])])])])}),0),_c('div',{staticClass:"answer_content"},[_c('div',{staticClass:"answers"},[_vm._v(" My Answer: "),(_vm.question_detail.user_answers)?_c('b',{domProps:{"innerHTML":_vm._s(
                    _vm.getMyAnswer(
                      _vm.question_detail.user_answer_title,
                      _vm.question_detail.answer_title,
                      _vm.question_detail.is_correct
                    )
                  )}}):_vm._e()]),_c('div',{staticClass:"answers"},[_vm._v(" Correct Answer: "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.getCorrectAnswer(_vm.question_detail.answer_title))}})]),_c('div',{staticClass:"clear"})])]],2)]),_c('div',{staticClass:"question"},[_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.explanation"))+" "),(_vm.isAdmin)?_c('EditReading',{attrs:{"question_detail":_vm.question_detail,"type":"explanation"}}):_vm._e()],1),_c('div',{staticClass:"exp"},[(_vm.lang === 'ZH-TW' || _vm.lang === 'EN-US')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.traditionalized(_vm.replaceLine(_vm.question_detail.exp), _vm.lang))}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.replaceLine(_vm.question_detail.exp))}})])])]),_c('GoogleAd')],1)]),_c('div',{class:{
      'col-sm-12': _vm.showPassage,
      'col-sm-6': !_vm.showPassage,
      'cover-child': true,
      'passage-box': true
    }},[_c('div',{staticClass:"cover-left"},[_c('div',{staticClass:"passage"},[_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.Passage"))+" "),(_vm.isAdmin)?_c('EditReading',{attrs:{"question_detail":_vm.question_detail,"type":"passage"}}):_vm._e()],1)]),_c('div',{staticClass:"exp"},[_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.question_detail.chapter_title))]),_c('div',{attrs:{"id":"passage"}})]),_c('div',{staticClass:"open",on:{"click":function($event){_vm.showPassage = !_vm.showPassage}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassage),expression:"!showPassage"}],staticClass:"text"},[_c('i',{staticClass:"fas fa-chevron-circle-left"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPassage),expression:"showPassage"}],staticClass:"text"},[_c('i',{staticClass:"fas fa-chevron-circle-right"})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }