import ApiService from "@/common/api.service";

export default {
  getLessons() {
    return ApiService.query("lessons");
  },
  getLesson(lessonId) {
    return ApiService.query(`lesson/${lessonId}`);
  },
  createLesson(lesson) {
    return ApiService.post("lesson", lesson);
  },
  updateLesson(lessonId, lesson) {
    return ApiService.patch(`lesson/${lessonId}`, lesson);
  },
  deleteLesson(lessonId) {
    return ApiService.delete(`lesson/${lessonId}`);
  }
};
