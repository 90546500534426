<template>
  <span>
    <el-button type="success" size="mini" @click="openEdit">
      <i class="fas fa-edit"></i>
      <b>Edit</b>
    </el-button>
    <el-dialog :visible.sync="edit" :title="`${type}-Edit`" width="80%">
      <el-form v-if="workQuestionDetail" ref="form" label-width="80px">
        <template v-if="type === 'passage'">
          <el-form-item label="Passage">
            <el-input
              type="textarea"
              :rows="10"
              v-model="workQuestionDetail.content.reading"
            ></el-input>
          </el-form-item>
        </template>
        <template v-if="type === 'audio'">
          <el-form-item label="Text">
            <el-input
              type="textarea"
              :rows="10"
              v-model="workQuestionDetail.content.talking_audio_text"
            ></el-input>
          </el-form-item>
        </template>
        <template v-if="type === 'question'">
          <el-form-item label="Question">
            <el-input
              type="textarea"
              :rows="5"
              v-model="workQuestionDetail.question"
            ></el-input>
          </el-form-item>
        </template>
        <template v-if="type === 'explanation'">
          <el-form-item label="">
            <el-input
              type="textarea"
              :rows="20"
              v-model="workQuestionDetail.exp"
            ></el-input>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEdit">Cancel</el-button>
        <el-button type="primary" @click="saveQuestion">
          Save
        </el-button>
      </span>
    </el-dialog>
  </span>
</template>

<script>
import _ from "lodash";
import TOEFL from "@/apis/toefl";

export default {
  components: {},
  mixins: [],
  props: ["type", "question_detail"],
  data() {
    return {
      edit: false,
      workQuestionDetail: null
    };
  },
  methods: {
    openEdit() {
      this.edit = true;
      this.workQuestionDetail = _.cloneDeep(this.question_detail);
    },
    closeEdit() {
      this.edit = false;
      this.workQuestionDetail = null;
    },
    async saveQuestion() {
      const id = this.workQuestionDetail.id;
      switch (this.type) {
        case "question":
          await Promise.all([
            TOEFL.editQuestion(id, {
              question: this.workQuestionDetail.question
            })
          ]).then(values => {
            this.edit = false;
            this.$message({
              message: this.$t("message.update_success"),
              type: "success",
              onClose: this.$router.go(0)
            });
          });
          break;
        case "passage":
        case "audio":
          await TOEFL.editQuestion(this.workQuestionDetail.id, {
            content: this.workQuestionDetail.content
          });
          this.edit = false;
          this.$message({
            message: this.$t("message.update_success"),
            type: "success",
            onClose: this.$router.go(0)
          });
          break;
        case "explanation":
          await TOEFL.editQuestion(this.workQuestionDetail.id, {
            exp: this.workQuestionDetail.exp
          });
          this.edit = false;
          this.$message({
            message: this.$t("message.update_success"),
            type: "success",
            onClose: this.$router.go(0)
          });
          break;
        default:
          return null;
      }
    }
  }
};
</script>

<style scoped>
.upload-demo {
  margin-bottom: 20px;
}
.option {
  margin: 20px 0;
}
.q_tags {
  margin: 10px 0 20px 0;
}

::v-deep .q_tags > .el-tag {
  margin-right: 8px;
  margin-bottom: 8px;
}
</style>
