<template>
  <div class="row cover">
    <div v-show="!showPassage" class="col-sm-6 cover-child question-box">
      <div class="cover-left">
        <div class="question" style="opacity: 1;">
          <div class="question-title" v-if="isBrowse">
            <span
              v-for="(title, index) in question_tags"
              @click="viewBrowse(index)"
              :key="index"
              :class="{
                active: question_detail.question_order === title.order,
                view_question: true,
                wrong: title.is_correct == 0
              }"
            >
              {{ title.order }}
            </span>
          </div>
          <div class="question-title" v-else>
            <span
              v-for="(title, index) in question_tags"
              @click="viewResolve(title.toefl_question_id)"
              :key="index"
              :class="{
                active: question_detail.question_order === title.order,
                view_question: true,
                wrong: title.is_correct == null || title.is_correct == 0
              }"
            >
              {{ title.order }}
            </span>
          </div>
        </div>
        <div class="question">
          <div class="resolve_content">
            <div class="title">
              {{ $t("pageTitle.Question") }}
              <EditReading
                v-if="isAdmin"
                :question_detail="question_detail"
                type="question"
              />
            </div>
          </div>
          <div class="exp">
            <div class="q_tags">
              <el-tag :key="tag.id" v-for="tag in question_detail.tags">
                {{ tag.name[$store.getters["user/langShortValue"]] }}
              </el-tag>
            </div>
            <h5 class="q_title" v-if="question_detail.type == 'click'">
              Look at the four squares [■] that indicate where the following
              sentence could be added to the passage. Where would the sentence
              best fit? Click on a square [■] to add the sentence to the
              passage.
            </h5>
            <h5 class="q_title" v-else v-html="question_detail.question"></h5>
            <p
              class="sentence"
              v-if="question_detail.type == 'click'"
              v-html="question_detail.question"
            ></p>
            <template v-if="isBrowse">
              <div class="options">
                <div
                  class="radio"
                  v-for="option in question_detail.options"
                  :key="option.id"
                >
                  <div :class="showAlert(option.id)">
                    <label :for="option.id">{{ option.title }}</label>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="options">
                <div
                  class="radio"
                  v-for="option in question_detail.options"
                  :key="option.id"
                >
                  <div
                    class="alert alert-success"
                    v-if="
                      isCorrect(
                        option.id,
                        question_detail.user_answers,
                        question_detail.is_correct
                      )
                    "
                  >
                    <label :for="option.id">{{ option.title }}</label>
                  </div>
                  <div
                    class="alert alert-danger"
                    v-else-if="
                      isError(
                        option.id,
                        question_detail.user_answers,
                        question_detail.is_correct
                      )
                    "
                  >
                    <label :for="option.id">{{ option.title }}</label>
                  </div>
                  <div v-else>
                    <div
                      :class="
                        question_detail.answers[0].answers.includes(option.id)
                          ? 'alert-success alert'
                          : 'alert'
                      "
                    >
                      <label :for="option.id">{{ option.title }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="answer_content">
                <div class="answers">
                  My Answer:
                  <b
                    v-if="question_detail.user_answers"
                    v-html="
                      getMyAnswer(
                        question_detail.user_answer_title,
                        question_detail.answer_title,
                        question_detail.is_correct
                      )
                    "
                  ></b>
                </div>
                <div class="answers">
                  Correct Answer:
                  <b
                    v-html="getCorrectAnswer(question_detail.answer_title)"
                  ></b>
                </div>
                <div class="clear"></div>
              </div>
            </template>
          </div>
        </div>
        <div class="question">
          <div class="resolve_content">
            <div class="title">
              {{ $t("pageTitle.explanation") }}
              <EditReading
                v-if="isAdmin"
                :question_detail="question_detail"
                type="explanation"
              />
            </div>
            <div class="exp">
              <span
                v-if="lang === 'ZH-TW' || lang === 'EN-US'"
                v-html="traditionalized(replaceLine(question_detail.exp), lang)"
              >
              </span>
              <span v-else v-html="replaceLine(question_detail.exp)"> </span>
            </div>
          </div>
        </div>
        <GoogleAd />
      </div>
    </div>
    <div
      :class="{
        'col-sm-12': showPassage,
        'col-sm-6': !showPassage,
        'cover-child': true,
        'passage-box': true
      }"
    >
      <div class="cover-left">
        <div class="passage">
          <div class="resolve_content">
            <div class="title">
              {{ $t("pageTitle.Passage") }}
              <EditReading
                v-if="isAdmin"
                :question_detail="question_detail"
                type="passage"
              />
            </div>
          </div>
          <div class="exp">
            <h2 class="text-center">{{ question_detail.chapter_title }}</h2>
            <div id="passage"></div>
          </div>
          <div class="open" @click="showPassage = !showPassage">
            <div class="text" v-show="!showPassage">
              <i class="fas fa-chevron-circle-left"></i>
            </div>
            <div class="text" v-show="showPassage">
              <i class="fas fa-chevron-circle-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import role from "@/mixins/role.js";
import ToeflMixin from "@/mixins/toefl.js";
import Translate from "@/mixins/translate.js";
import GoogleAd from "@/views/ad/GoogleAd";
import EditReading from "@/views/toefl/toeflResolve/Edit/Reading";
import $ from "jquery";

export default {
  props:["question_detail", "question_tags", "title", "lang", "isBrowse"],
  mixins: [ToeflMixin, Translate, role],
  components: {
    EditReading,
    GoogleAd
  },
  computed:{
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    }
  },
  data() {
    return {
      showPassage: false
    };
  },
  watch: {
    question_detail() {
      this.setPassage();
    }
  },

  mounted() {
    this.setPassage();
  },
  destroyed(){
    this.stream.getTracks().forEach(track => {
      track.stop();
    });
  },

  methods: {
    showAlert(id) {
      if(this.question_detail.answers[0].answers.includes(id)) {
        return "alert-success alert";
      } else if(this.question_detail.answers[1] && this.question_detail.answers[1].answers.includes(id)) {
        return "alert-success alert alert-blue";
      } else {
        return "alert";
      }
    },
    isCorrect(answers,user_answers, isCorrect) {
      let correct = false;
      if(isCorrect === 1){
        if(user_answers.includes(answers)){
          correct = true;
        }else{
          correct = false;
        };
      }
      return correct;
    },
    isError(answers,user_answers, isCorrect) {
      let error = false;
      if(isCorrect === 0){
        if(user_answers.includes(answers) && !this.question_detail.answers[0].answers.includes(answers) ){
          error = true;
        };
      }
      return error;
    },
    setPassage() {
      let passage = document.getElementById("passage");
      if (this.question_detail.chapter_content.html) {
        passage.innerHTML = this.question_detail.chapter_content.html;
        let vm = this;
        $(".paragraph").each(function(item) {
          let questionOrders = $(this).data("light");
          if (questionOrders?.includes(vm.question_detail.question_order)) {
            $(this).addClass("paragraph-anchor");
            $(this).find("b").addClass("light");
          }
        });

        $("[data-light-order]").each(function(item) {
          let questionOrder = $(this).data("light-order");
          if (questionOrder === vm.question_detail.question_order) {
            $(this).addClass("light");
          } else {
            $(this).removeClass("light");
          }
        });
      } else {
        passage.innerHTML = this.question_detail.content.html;
      }
      this.scrollToParagraph();
      let dom = document.getElementsByClassName("insert-area");
      for (var i = 0; i < dom.length; i++) {
        if (this.question_detail.type === "click") {
          if (this.mode === "1") {
            dom[i].innerHTML = " <b class='text-success text-option'><span style='font-size:28px;line-height: 14px'>■</span></b> ";
          } else {
            dom[i].innerHTML = ` <b class="text-success">[${dom[i].getAttribute(
              "data-answer"
            )}]  <span style="line-height: 14px">■</span></b> `;
          }
        } else {
          dom[i].innerHTML = null;
        }
      }
    },
    //段落跳转
    scrollToParagraph() {
      let paragraph = document.getElementsByClassName("paragraph-anchor")[0];
      let text = document.getElementsByClassName("passage")[0];
      if (paragraph !== undefined) {
        text.scrollTo(0, paragraph.offsetTop);
      }
    },
    viewResolve(question_id) {
      this.$parent.viewResolve(question_id);
    },
    viewBrowse(index){
      this.$parent.viewBrowse(index);
    }
  }
};
</script>

<style scoped src="@/views/toefl/toeflResolve/style.css"></style>
<style scoped>
.question {
  overflow: auto;
}
::v-deep .article {
  font-size: 16px;
  color: grey;
  line-height: 30px;
}
::v-deep .article .paragraph {
  margin-bottom: 20px;
}
::v-deep .light {
  background-color: #fff;
  color: grey;
}
::v-deep .paragraph-anchor .light {
  background-color: var(--themeColor);
  color: #fff;
}
::v-deep .article br {
  display: none;
}
.answer_content .answers {
  margin-right: 20px;
}
.alert-blue {
  color: white;
  background-color: #409eff;
  border-color: #409eff;
}
/* ::v-deep .light {
  background-color: var(--themeColor);
  color: #fff;
} */
.cover {
  margin: 0;
}
.passage {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
}
.question {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
}
.passage-box {
  padding-left: 40px;
  padding-right: 0;
  background: white;
}
.question-box {
  padding-left: 0;
  padding-right: 20px;
  background: white;
}
.open {
  width: 40px;
  height: 70px;
  padding: 20px 0;
  background-color: #f2f3f5;
  font-size: 22px;
  top: 50%;
  transform: translateY(-35px);
  left: 0px;
  border-radius: 5px 0 0 5px;
}

.passage {
  background: #f2f3f5;
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
</style>
