<template>
  <div>
    <el-form ref="form" label-width="80px">
      <el-form-item label="Tags">
        <div class="q_tags">
          <el-select
            v-model="selectedTags"
            multiple
            placeholder="Please select"
            style="width:100%"
          >
            <el-option
              v-for="tag in tags"
              :key="tag.id"
              :label="tag.name[$store.getters['user/langShortValue']]"
              :value="tag.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="Question">
        <div class="option">
          <el-input
            type="textarea"
            :rows="2"
            v-model="newQuestionDetail.question"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="Options">
        <div class="options">
          <div
            class="option"
            v-for="option in newQuestionDetail.options"
            :key="option.number"
          >
            <el-input v-model="option.title" placeholder="Please enter content">
              <template slot="prepend">
                {{ optionsArr[option.number - 1] }}
              </template>
            </el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="Correct answer">
        <div class="option">
          <el-select
            v-model="correctAnswer"
            multiple
            placeholder="Please enter content"
            style="width:100%"
          >
            <el-option
              v-for="option in newQuestionDetail.options"
              :key="option.id"
              :label="optionsArr[option.number - 1]"
              :value="option.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import TOEFL from "@/apis/toefl";

export default {
  components: {},

  mixins: [],

  props: ["newQuestionDetail", "type"],
  data() {
    return {
      selectedType: "",
      tags: [],
      selectedTags: [],
      correctAnswer: []
    };
  },
  computed: {
    optionsArr() {
      let wordArr = [];
      for (var i = 65; i < 90; i++) {
        wordArr.push(String.fromCharCode(i));
      }
      return wordArr;
    }
  },
  watch: {
    selectedTags(value) {
      let selectTags = [];
      if (value.length > 0) {
        this.tags.map(tag => {
          value.map(selectTag => {
            if (tag.id == selectTag) {
              selectTags.push(tag);
            }
          });
        });
      }
      this.newQuestionDetail.tags = selectTags;
    },
    correctAnswer(value) {
      let letter_answers = [];
      let options = [];
      if (value.length > 0) {
        this.newQuestionDetail.options.map(option => {
          value.map(answer => {
            if (option.id == answer) {
              letter_answers.push(option.letter);
              options.push(option.letter);
            }
          });
        });
      }
      this.newQuestionDetail.answers[0] = {
        ...this.newQuestionDetail.answers[0],
        answers: value,
        letter_answers: letter_answers,
        options: options
      };
    }
  },

  mounted() {
    this.correctAnswer = [...this.newQuestionDetail.answers[0].answers];
    this.getAllTags();
    this.setOption();
  },

  methods: {
    async getAllTags() {
      const res = await TOEFL.getAllTags({ section: this.type });
      this.tags = res.tags;
      let vm = this;
      this.newQuestionDetail.tags.map(function(tag) {
        vm.selectedTags.push(tag.id);
      });
    },
    setOption() {
      let vm = this;
      this.newQuestionDetail.options.forEach((options, index) => {
        vm.newQuestionDetail.options[
          index
        ].title = vm.newQuestionDetail.options[index].title.replace(
          `${this.optionsArr[index]}. `,
          ""
        );
      });
    },
    sortOptions() {
      let vm = this;
      this.newQuestionDetail.options.forEach((options, index) => {
        vm.newQuestionDetail.options[index].number = index + 1;
        vm.newQuestionDetail.options[index].letter = this.optionsArr[index];
      });
    },
    removeOptions(index) {
      this.newQuestionDetail.options.splice(index, 1);
      this.sortOptions();
    },
    addOption() {
      let number = this.newQuestionDetail.options[
        this.newQuestionDetail.options.length - 1
      ].number;
      this.newQuestionDetail.options.push({
        id: 0,
        toefl_question_id: 0,
        number: number + 1,
        title: "",
        created_at: null,
        updated_at: null,
        letter: ""
      });
      this.sortOptions();
    }
  }
};
</script>

<style scoped>
.option {
  display: flex;
}
.removeBtn {
  cursor: pointer;
  margin-left: 10px;
}
::v-deep .el-input-group__prepend {
  width: 40px;
}
.add-button {
  font-size: 20px;
  cursor: pointer;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px dashed rgb(192, 204, 218);
  border-radius: 6px;
}
.add-button:hover {
  border-color: var(--themeColor);
  color: var(--themeColor);
}
.option {
  margin-bottom: 20px;
}
.q_tags {
  margin: 10px 0 20px 0;
}

::v-deep .q_tags > .el-tag {
  margin-right: 8px;
  margin-bottom: 8px;
}
</style>
